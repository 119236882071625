import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { SolutionsData } from "./solutionsContent";

const SolutionsNavbar = ({ selectedTab, onTabClick }: any) => {

  return (
    <div className='md:max-w-[34%] min-w-[34%] sm:w-[60%] sm:max-w-full flex flex-col md:sticky md:top-[80px] md:max-h-[calc(100vh-80px)]'>
      {Object.keys(SolutionsData).map((item, index) => {
        return (
          <div
            key={item}
            className={
              SolutionsData[selectedTab] === SolutionsData[item]
                ? "space-y-0 bg-[#E6EEFF] text-blue-700 cursor-pointer"
                : "space-y-0 bg-[#FAFBFC] text-[#505F79] cursor-pointer"
            }
            onClick={() => onTabClick(item)}
          >
            <div className="flex md:gap-8 sm:gap-2 px-5 lg:py-5 md:py-1">
              <p className="text-lg sm:text-lg font-bold">0{index + 1}.</p>
              <p className="text-base sm:text-regular font-medium sm:max-w-xl md:text-base lg:text-xl min-w-[80%]">
                <Trans i18nKey={`${SolutionsData[item].heading}`} />
              </p>
            </div>
            <div className="h-[1px] sm:block shrink-0 grow bg-gray-300" />
          </div>
        );
      })}
    </div>
  );
};

export default SolutionsNavbar;
