import React, { useRef } from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import sol1Image from "images/solutions-page/sol1.jpg"
import sol2Image from "images/solutions-page/sol2.jpg"
import sol3Image from "images/solutions-page/sol3.jpg"
import sol4Image from "images/solutions-page/sol4.jpg"
import sol5Image from "images/solutions-page/sol5.jpg"

export const SolutionsData: any = {
    "sol1": {
      heading: 'solutions-page.content.sol1.heading',
      challenge: 'solutions-page.content.sol1.challenge',
      image: sol1Image,
      approach: [
        {
          title: 'solutions-page.content.sol1.approach.title1',
          desc: 'solutions-page.content.sol1.approach.desc1'
        },
        {
          title: 'solutions-page.content.sol1.approach.title2',
          desc: 'solutions-page.content.sol1.approach.desc2'
        },
        {
          title: 'solutions-page.content.sol1.approach.title3',
          desc: 'solutions-page.content.sol1.approach.desc3'
        },
        {
          title: 'solutions-page.content.sol1.approach.title4',
          desc: 'solutions-page.content.sol1.approach.desc4'
        },
        {
          title: 'solutions-page.content.sol1.approach.title5',
          desc: 'solutions-page.content.sol1.approach.desc5'
        },
        {
          title: 'solutions-page.content.sol1.approach.title6',
          desc: 'solutions-page.content.sol1.approach.desc6'
        }
      ]
    },
    "sol2": {
      heading: 'solutions-page.content.sol2.heading',
      challenge: 'solutions-page.content.sol2.challenge',
      image: sol2Image,
      approach: [
        {
          title: 'solutions-page.content.sol2.approach.title1',
          desc: 'solutions-page.content.sol2.approach.desc1'
        },
        {
          title: 'solutions-page.content.sol2.approach.title2',
          desc: 'solutions-page.content.sol2.approach.desc2'
        },
        {
          title: 'solutions-page.content.sol2.approach.title3',
          desc: 'solutions-page.content.sol2.approach.desc3'
        },
        {
          title: 'solutions-page.content.sol2.approach.title4',
          desc: 'solutions-page.content.sol2.approach.desc4'
        },
        {
          title: 'solutions-page.content.sol2.approach.title5',
          desc: 'solutions-page.content.sol2.approach.desc5'
        },
        {
          title: 'solutions-page.content.sol2.approach.title6',
          desc: 'solutions-page.content.sol2.approach.desc6'
        }
      ]
    },
    "sol3": {
      heading: 'solutions-page.content.sol3.heading',
      challenge: 'solutions-page.content.sol3.challenge',
      image: sol3Image,
      approach: [
        {
          title: 'solutions-page.content.sol3.approach.title1',
          desc: 'solutions-page.content.sol3.approach.desc1'
        },
        {
          title: 'solutions-page.content.sol3.approach.title2',
          desc: 'solutions-page.content.sol3.approach.desc2'
        },
        {
          title: 'solutions-page.content.sol3.approach.title3',
          desc: 'solutions-page.content.sol3.approach.desc3'
        }
      ]
    },
    "sol4": {
      heading: 'solutions-page.content.sol4.heading',
      challenge: 'solutions-page.content.sol4.challenge',
      image: sol4Image,
      approach: [
        {
          title: 'solutions-page.content.sol4.approach.title1',
          desc: 'solutions-page.content.sol4.approach.desc1'
        },
        {
          title: 'solutions-page.content.sol4.approach.title2',
          desc: 'solutions-page.content.sol4.approach.desc2'
        },
        {
          title: 'solutions-page.content.sol4.approach.title3',
          desc: 'solutions-page.content.sol4.approach.desc3'
        },
        {
          title: 'solutions-page.content.sol4.approach.title4',
          desc: 'solutions-page.content.sol4.approach.desc4'
        }
      ]
    },
    "sol5": {
      heading: 'solutions-page.content.sol5.heading',
      challenge: 'solutions-page.content.sol5.challenge',
      image: sol5Image,
      approach: [
        {
          title: 'solutions-page.content.sol5.approach.title1',
          desc: 'solutions-page.content.sol5.approach.desc1'
        },
        {
          title: 'solutions-page.content.sol5.approach.title2',
          desc: 'solutions-page.content.sol5.approach.desc2'
        },
        {
          title: 'solutions-page.content.sol5.approach.title3',
          desc: 'solutions-page.content.sol5.approach.desc3'
        }
      ]
    }
  }

  const myStyles = css`
  &:before {
    content: "...................";
    position: absolute;
    height: 100%;
    overflow: hidden; /* hide overflow on wrappers accordingly */
    font-weight: 400; /* using periods allows for customization of weight */
    letter-spacing: -10px; /* allows you to adjust dot spacing */
    writing-mode: vertical-rl; /* use for vertical orientation */
    text-orientation: upright; /* use for vertical orientation */
    z-index: -1; /* was needed to get the dots below circular icons */
    color: #A6BFF7 !important;
}
  `

const IconsDiv = styled.div`
  ${myStyles}
`;

const SolutionsContent = (selectedTab: any) => {
  const { t } = useTranslation()
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={contentRef} className='min-w-full sm:w-[60%] flex flex-col gap-5'>
        <h2 className="text-4xl text-blue-700 font-light sm:text-3xl md:text-4xl">
            {t(`solutions-page.content.${Object.values(selectedTab)}.heading`)}
        </h2>
        <div className="h-[1px] sm:block shrink-0 grow bg-gray-200 mt-4" />
        <img
            src={SolutionsData[Object.values(selectedTab)].image}
            className="w-full"
            alt={t(`solutions-page.content.${Object.values(selectedTab)}.heading`)}
        />
        <h4 className="text-base text-blue-700 uppercase font-semibold sm:text-xl md:text-2xl">{t('solutions-page.challenge')}</h4>
        <p className="text-base sm:text-lg sm:max-w-xl md:text-xl min-w-[80%]">
            {t(`solutions-page.content.${Object.values(selectedTab)}.challenge`)}
        </p>
        <div className="h-[1px] sm:block shrink-0 grow bg-gray-200 mt-4" />
        <h4 className="text-base text-blue-700 uppercase font-semibold sm:text-xl md:text-2xl">{t('solutions-page.approach')}</h4>
        {SolutionsData[Object.values(selectedTab)]["approach"].map((item, index) => {
            if (index === SolutionsData[Object.values(selectedTab)]["approach"].length - 1)
            return (
                <div className="space-y-8 overflow-hidden">
                    <div className="flex items-center gap-8">
                        <div className="w-3 h-3 rounded-full bg-blue-300 ml-[7px]"></div>
                        <p className="text-xl sm:text-lg font-semibold sm:max-w-xl md:text-xl min-w-[80%] text-[#0B1730]">
                            <Trans i18nKey={`${item.title}`} />
                        </p>
                    </div>
                    <p className="text-xl sm:text-lg sm:max-w-xl md:text-xl min-w-[80%] ml-12 text-[#0B1730]">
                        <Trans i18nKey={`${item.desc}`} />
                    </p>
                </div>)
            else return (<IconsDiv className="space-y-8 over overflow-hidden">
                    <div className="flex items-center gap-8">
                        <div className="w-3 h-3 rounded-full bg-blue-300 ml-[7px]"></div>
                        <p className="text-xl sm:text-lg font-semibold sm:max-w-xl md:text-xl min-w-[80%] text-[#0B1730]">
                            <Trans i18nKey={`${item.title}`} />
                        </p>
                    </div>
                    <p className="text-xl sm:text-lg sm:max-w-xl md:text-xl min-w-[80%] ml-12 text-[#0B1730]">
                        <Trans i18nKey={`${item.desc}`} />
                    </p>
                </IconsDiv>
            )
        })}
        <div className="h-[1px] sm:block shrink-0 grow bg-gray-200 mt-4" />
        <h4 className="text-base text-blue-700 uppercase font-semibold sm:text-xl md:text-2xl">{t('solutions-page.result')}</h4>
        <p className="text-base sm:text-lg sm:max-w-xl md:text-xl min-w-[80%]">
            {t(`solutions-page.content.${Object.values(selectedTab)}.result`)}
        </p>
    </div>
  );
};

export default SolutionsContent;